<footer>
  <div class="img-content" style="display: flex; align-items: center;">
    <a
      href="https://www.gov.co/"
      target="_blank"
      title="Ir al portal GOV.CO"
      role="button"
      tabindex="-1"
      aria-label="Portal del Estado Colombiano - GOV.CO">GOV.CO</a>
    <span class="sr-only ">Logo marca Colombia</span>
    <img
    role="button"
    tabindex="-1"
      src="https://www.mintic.gov.co/portal/715/channels-616_marca_colombia.png"
      alt="Marca Colombia"
      width="48px"
      class="img-fluid border-left border-white img-footer">
  </div>
<div class="column-1">
  <ul role="tree">
    <li role="treeitem" tabindex="-1"><b>Dirección de Impuestos y Aduanas Nacionales</b></li>
    <li role="treeitem" tabindex="-1">NIT: 800.197.268-4</li>
    <li role="treeitem" tabindex="-1">Dirección: Sede principal | Bogotá, Nivel Central, carrera 8 Nº 6C - 38</li>
    <li role="treeitem" tabindex="-1">Edificio San Agustín</li>
    <li role="treeitem" tabindex="-1">Código Postal: 111711</li>
    <li role="treeitem" tabindex="-1">Horario Contact Center: Lunes a Viernes 8:00 a.m. a 7:00 p.m. y Sábados de 8:00 a.m.</li>
    <li role="treeitem" tabindex="-1">a 12:00 m.</li>
  </ul>

  <section class="he_footer_redes" role="tree">
    <div role="treeitem" >
      <a role="button"
      tabindex="-1" href="https://twitter.com/DIANColombia" target="_blank">
          <img src="./../../../../assets/X-54x54C.png" alt="Twitter">
      </a>
    </div>
    <div role="treeitem" style="padding-top: 3px;">
      <a role="button"
      tabindex="-1" href="https://www.youtube.com/channel/UCkaPJ8afIoa_RDKEHaQIxrw" target="_blank">
          <img src="./../../../../assets/youtube.png" alt="Youtube">
      </a>

    </div>
    <div role="treeitem" style="padding-top: 3px;">
        <a role="button"
      tabindex="-1" href="https://www.linkedin.com/company/diancolombia/" target="_blank">
          <img src="./../../../../assets/linkedin.png" alt="Linkedin">
        </a>
      </div>
        <div role="treeitem" style="padding-top: 3px;">
          <a role="button"
      tabindex="-1" href="https://www.facebook.com/DIANCol/" target="_blank">
            <img src="./../../../../assets/facebook.png" alt="Facebook">
          </a>
        </div>
         <div style="padding-top: 3px;" role="treeitem"><a role="button"
      tabindex="-1" href="https://www.instagram.com/diancolombia/?hl=es-la" target="_blank">
            <img src="./../../../../assets/instagram.png" alt="Instagram">
        </a>

      </div>
        <div role="treeitem">
          <a role="button"
      tabindex="-1" href="https://www.tiktok.com/@diancolombia" target="_blank">
              <img src="./../../../../assets/Tiktok-54x54C.png" alt="Tiktok">
          </a>

        </div>
      </section>
</div>

<div class="column-2">
  <ul role="tree">
    <li role="treeitem">
      <h6>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5"
      stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M9 3a1 1 0 0 1 .877 .519l.051 .11l2 5a1 1 0 0 1 -.313 1.16l-.1 .068l-1.674 1.004l.063 .103a10 10 0 0 0 3.132 3.132l.102 .062l1.005 -1.672a1 1 0 0 1 1.113 -.453l.115 .039l5 2a1 1 0 0 1 .622 .807l.007 .121v4c0 1.657 -1.343 3 -3.06 2.998c-8.579 -.521 -15.418 -7.36 -15.94 -15.998a3 3 0 0 1 2.824 -2.995l.176 -.005h4z" stroke-width="0"
      fill="currentColor" />
    </svg><b>Contacto </b></h6></li>
    <li role="treeitem" style="padding-top: 1rem;"><b>Contact Center</b></li>
    <li role="treeitem">57 601 307 8064 Línea de Contact Center atención de Impuestos</li>
    <li role="treeitem">57 601 307 8065 Línea de Contact Center atención de Aduanas</li>
    <li role="treeitem">Nivel central: PBX 601 7428973 / (+57) 3103158107</li>
    <li role="treeitem" style="padding-top: 1rem;"><b>Servicio en Línea de Contacto</b></li>
    <li role="treeitem">
      <a role="button"
      tabindex="-1" href="https://www.dian.gov.co/atencionciudadano/PQSRD/Paginas/Inicio.aspx" target="_blank">PQSR y Denuncias</a> |
      <a role="button"
      tabindex="-1" href="https://www.dian.gov.co/atencionciudadano/contactenos/Paginas/puntosdecontacto.aspx" target="_blank">Puntos de contacto </a></li>
    <li role="treeitem">
      <a role="button"
      tabindex="-1" href="https://www.dian.gov.co/Documents/POLITICA_GENERAL_DE_SEGURIDAD_Y_PRIVACIDAD_DE_LA_INFORMACION.pdf" target="_blank">
        Política de Seguridad de la Información
      </a>
    </li>
    <li role="treeitem">
      <a role="button"
      tabindex="-1" href="https://www.dian.gov.co/Paginas/Privacidad.aspx" target="_blank">
        Políticas de privacidad y términos de uso
      </a>
    </li>
    <li role="treeitem">
      <a role="button"
      tabindex="-1" href="https://www.dian.gov.co/normatividad/Normatividad/Circular-000001-de-25-01-2019.pdf" target="_blank">
        Política de Tratamiento de Datos Personales
      </a>
    </li>
  </ul>
</div>
</footer>
<div class="footer_2">
  v1.0.1
</div>
