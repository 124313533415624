import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { FormularioComponent } from '../formulario';
import { RecaptchaService } from '../../../../Infraestructure/recaptcha/recaptcha.service';
import { LoadingComponent } from '../../components/loading/loading.component';
import { adapterToken } from '../../../adapters/adapterToken';
import { generateRandomString } from '../../utils/generateToken';
import { FirstnavbarComponent } from '../../components/firstnavbar/firstnavbar.component';
import { environment } from 'environments/environment';
// import { KEY_CAPTCHAP } from './constants';

declare var turnstile: any;

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './../../../../Presentation/pages/home/home.component.html',
  styleUrl: './../../../../Presentation/pages/home/home.component.scss',
  imports: [
    FormularioComponent,
    RecaptchaModule,
    LoadingComponent,
    FirstnavbarComponent,
  ],
})
class HomeComponent implements OnInit {
  error = '';
  loading = false;
  viewRecaptcha = true;

  // public enabledCaptcha =
  //   environment.enableRecaptcha ||
  //   import.meta.env.NG_APP_RECAPTCHA_ENABLED === 'true' ||
  //   process.env.NG_APP_RECAPTCHA_ENABLED === 'true' ||
  //   false;
  public enabledCaptcha: any = environment.enableRecaptcha == 'true';

  // public readonly recaptcha =
  //   environment.recaptcha ||
  //   process.env.NG_APP_RECAPTCHA ||
  //   import.meta.env.NG_APP_RECAPTCHA;
  public readonly recaptcha = environment.recaptcha;

  constructor(
    private router: Router,
    private elRef: ElementRef
    //private recaptchaService: RecaptchaService
  ) {}

  ngOnInit() {
    window.sessionStorage.clear();
  }

  private buildCatpchaCludflare() {  
    const turnstileScript = document.createElement('script');
    turnstileScript.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    turnstileScript.async = true;
    turnstileScript.defer = true;
    document.body.appendChild(turnstileScript);

    //if (typeof environment.captchaKeyCloudflare === 'string') {      
      turnstileScript.onload = () => {
        // @ts-ignore
        turnstile.render(this.elRef.nativeElement.querySelector('#cf-turnstile'), {
          sitekey: environment.captchaKeyCloudflare,
          callback: (token: string) => {
            this.resolved(token);
          }
        });
      }; 
    //}
  }

  private resolved(captchaToken: any) {
    this.loading = true;
    this.error = '';

    if (!captchaToken) {
      this.error = 'Token is invalid';
      return;
    }

    const token = adapterToken(captchaToken);
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('dateToken', Date.now().toString());
    setTimeout(() => this.router.navigate(['info']), 1000);
  }

  // resolved(captchaResponse: any) {
  //   this.loading = true;
  //   this.error = '';

  //   if (captchaResponse) {
  //     this.recaptchaService.getVerificationCaptcha(captchaResponse).subscribe({
  //       next: (response: string) => {
  //         this.loading = false;
  //         this.error = '';
  //         if (response !== 'Token is invalid') {
  //           const token = adapterToken(captchaResponse);
  //           window.sessionStorage.setItem('token', token);
  //           window.sessionStorage.setItem('dateToken', Date.now().toString());
  //           setTimeout(() => this.router.navigate(['info']), 1000);
  //         } else {
  //           this.error = 'Verification failed. Please try again.';
  //           this.loading = false;
  //         }
  //       },
  //       error: (error) => {
  //         console.error('Error response:', error);
  //         if (error.error.isTrusted) {
  //           this.error =
  //             'Error 500: ha ocurrido un error en el servidor. Por favor, haga ';
  //         } else {
  //           this.error = error.error || 'An unexpected error occurred';
  //         }

  //         this.loading = false;
  //       },
  //       complete: () => {
  //         console.error('Verification complete');
  //       },
  //     });
  //   }
  // }

  public handleClick() {
    this.buildCatpchaCludflare(); 
    if (window.localStorage.getItem('enabled_captchap') === 'false') {
      const dataToken = generateRandomString(200);
      window.sessionStorage.setItem('token', dataToken);
      window.sessionStorage.setItem('dateToken', Date.now().toString());
      this.router.navigate(['/info']);
    } else if (this.enabledCaptcha || this.enabledCaptcha == 'true') {
      this.viewRecaptcha = true;
    } else {
      const dataToken = generateRandomString(200);
      window.sessionStorage.setItem('token', dataToken);
      window.sessionStorage.setItem('dateToken', Date.now().toString());
      this.router.navigate(['/info']);
    }
  }
}

export default HomeComponent;
